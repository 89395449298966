.construction {
  height: 100vh;
  width: 100vw;
  background-image: radial-gradient(circle, #000E24, #102047);
  display: flex;
  justify-content: center;
  align-items: center;

  .construction-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 3s;
    animation-fill-mode: forwards;
  }

  h1 {
    color: white;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
  }

  p {
    color: white;
    font-size: 24px;
    padding: 20px 0px;
  }

  .contact-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
    width: 200px;
    height: 50px;
    font-size: 20px;
    background-color: #4FA0F2;
    border-radius: 50px;
    cursor: pointer
  }
}

@media screen and (max-width: 1000px) {
  .construction {
    height: 100vh;
    width: 100vw;
    background-image: radial-gradient(circle, #000E24, #102047);
    display: flex;
    justify-content: center;
    align-items: center;

    .construction-content {
      width: 80%;
      text-align: center;
    }

    h1 {
      font-size: 30px;
    }

    p {
      font-size: 14px;
    }

    .contact-button {
      width: 150px;
      font-size: 18px;
    }
  }
}