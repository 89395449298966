@keyframes slide {
  0% {
    right: -50%;
  }

  100% {
    right: 0;
  }
}

nav{
  z-index: 1;
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
}

img{
  width: 100px;
  margin-bottom: -5px;
  margin-right: 10px;
}
.title{
  font-size: 48px;
  padding: 30px 50px;
}

.links{
    padding: 30px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-button{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
  width: 200px;
  height: 50px;
  font-size: 20px;
  background-color: #4FA0F2;
  border-radius: 50px;
  cursor: pointer
}

.logo-link{
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-link, .logo-link, .home-logo-link{
  text-decoration: none;
  color: white;
  margin-right: 20px;
}

.home-logo-link{
  margin-right: 0px;
}

.navbar-link:hover {
  color: #b2d6f9;
}

.hamburger{
  display: none;
}

@media screen and (max-width: 1000px) {
    img {
      width: 50px;
      margin-bottom: -10px;
      margin-right: 10px;
    }

    .title {
      font-size: 30px;
      padding: 30px 20px;
    }

    .contact-button {
      display: none;
    }

    .links{
      display:none;
    }

    .hamburger{
      display: block;
      margin: 0px 20px;
    }

    .links{
      display: none;
    }

    .links.active{
      animation: slide 1s;
      animation-fill-mode: forwards;
      position: absolute;
      right: 0;
      top: 80px;
      width: 50vw;
      display: flex;
      flex-direction: column;
      justify-content: start;
      border-radius: 10px;
      background-color: #1869af;
      padding-bottom: 0px;
    }

    .navbar-link{
      margin-right: 0px;
      color: white;
      font-weight: 500;
      margin-bottom: 30px;
    }
}