@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.contact {
  height: 100vh;
  width: 100vw;
  background-color: #000E24;
  background-image: radial-gradient(circle, #000E24, #102047);
  display: flex;
  justify-content: center;
  align-items: center;

  h1{
    color: white;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
  }

  .contact-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: start;
    animation: fadeIn 3s;
    animation-fill-mode: forwards;
  }

  p {
    color: white;
    font-size: 24px;
    padding: 20px 0px;
  }

  .contact-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px;
    width: 200px;
    height: 50px;
    font-size: 20px;
    color: white;
    background-color: #4FA0F2;
    border-radius: 50px;
    border: none;
    cursor: pointer
  }

  .contact-form{
    width: 75%;


    input[type='text']{
        margin-right: 2%;
    }
    input[type='text'],
      input[type='email'] {
        outline: none;
        margin-top: 50px;
        margin-bottom: 100px;
        width: 49%;
        border: 0;
        background: transparent;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
        border: none;
        border-bottom: 2px solid grey;
      }
      textarea {
          outline: none;
          width: 100%;
          border: 0;
          background: transparent;
          height: 60px;
          font-size: 16px;
          color: #fff;
          padding: 20px;
          margin-bottom: 50px;
          resize: none;
          box-sizing: border-box;
          border: none;
          border-bottom: 2px solid grey;
        }

        /* width */
        ::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: white;
          border-radius: 10px;
        }
  }
}

@media screen and (max-width: 1000px) {
  .contact {
      h1 {
        font-size: 30px;
      }

      .contact-content {
        width: 80%;
        align-items: center;
      }

      p {
        font-size: 14px;
      }

      .contact-button {
        width: 150px;
        font-size: 18px;
      }

      .contact-form {
        width: 100%;
      }
    }
}
